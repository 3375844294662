<template>
  <div>
    <RoleCard role="mentor">
      <template #label>Наставник</template>
      <template #label_info
        >Шаг 2&nbsp;из&nbsp;2&nbsp;&mdash; заполнение данных</template
      >
      <template #label_img>
        <img src="@/assets/images/reg_mentor.svg" />
      </template>
      <template #body>
        <MentorForm :talent-user="talentUser"></MentorForm>
      </template>
    </RoleCard>
  </div>
</template>

<script>
import MentorForm from "@/components/registration/MentorForm.vue";
import RoleCard from "@/components/registration/RoleCard.vue";
import store from "@/store";
export default {
  name: "RegistrationMentorTrack",
  components: {
    MentorForm,
    RoleCard,
  },
  metaInfo() {
    return {
      title: "Регистрация для Наставников",
    };
  },
  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch("user/getTalentUser");
      next();
    } catch (error) {
      next();
    }
  },
  computed: {
    talentUser() {
      return this.$store.state.user.talentUser;
    },
  },
};
</script>

<style></style>
